import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import LeftArrow from "../assets/leftArrow.png";
import RightArrow from "../assets/rightArrow.png";
import { testimonialsData } from "../data/testimonialsData";

const Container = styled.div`
  padding: 0 2rem;
  display: flex;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: 2rem;
  text-transform: uppercase;
  div {
    span:nth-child(1) {
      color: var(--orange);
    }
    span:nth-child(2) {
      color: white;
    }
  }
`;

const Title = styled.span`
  font-size: 3rem;
  font-weight: bold;
  :nth-child(1) {
    font-size: 1rem;
    color: var(--orange);
  }
  :nth-child(3) {
    color: white;
  }
`

const Review = styled(motion("span"))`
  color: white;
  letter-spacing: 2px;
  text-transform: none;
  font-weight: normal;
  line-height: 40px;
`

const RightContainer = styled.div`
  flex: 1 1;
  position: relative;
`;

const BorderBox = styled(motion("div"))`
  position: absolute;
  height: 20rem;
  width: 17rem;
  right: 9rem;
  top: .9rem;
  border: 2px solid orange;
`;

const OrangeBox = styled(motion("div"))`
  position: absolute;
  height: 20rem;
  width: 17rem;
  right: 7rem;
  top: 4rem;
  background: var(--planCard);
`;

const Image = styled(motion("img"))`
  height: 20rem;
  width: 17rem;
  position: absolute;
  right: 8rem;
  top: 2rem;
  object-fit: cover;
`

const ArrowGroup = styled.div`
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  left: 3rem;
  img {
    width: 1.5rem;
    cursor: pointer;
  }
`

const Testimonials = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const length = testimonialsData.length;
  const transition = { type: "spring", duration: 2 };

  const handleSwitch = (type) => {
    if (type === "left") {
      selectedIndex === 0 ? setSelectedIndex(length - 1) : setSelectedIndex((prev) => prev - 1);
    } else {
      selectedIndex === length - 1 ? setSelectedIndex(0) : setSelectedIndex((prev) => prev + 1);
    }
  }
  return (
    <Container>
      <LeftContainer>
        <Title>Testimonials</Title>
        <Title className="stroke-text">what they</Title>
        <Title>say about us</Title>
        <Review
          key={selectedIndex}
          initial={{ opacity: 0, x: -100 }} 
          whileInView={{ opacity: 1, x: 0 }} 
          transition={transition}
        >
          {testimonialsData[selectedIndex].review}
        </Review>
        <div>
          <span>{testimonialsData[selectedIndex].name}</span>
          <span> - {testimonialsData[selectedIndex].status}</span>
        </div>
      </LeftContainer>
      <RightContainer>
        <BorderBox 
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ...transition, duration: 2 }}
        />
        <OrangeBox
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ...transition, duration: 2 }}
        />
        <Image
          key={selectedIndex}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={transition}
          src={testimonialsData[selectedIndex].image} 
          alt="" 
         />
        <ArrowGroup>
          <img src={LeftArrow} alt="" onClick={() => handleSwitch("left")} />
          <img src={RightArrow} alt="" onClick={() => handleSwitch("right")} />
        </ArrowGroup>
      </RightContainer>
    </Container>
  )
}

export default Testimonials;