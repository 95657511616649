import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import NumberCount from "number-counter";
import Header from "./Header";
import HeartImg from "../assets/heart.png";
import HeroImg from "../assets/hero_image.png";
import HeroBackImg from "../assets/hero_image_back.png";
import CaloriesImg from "../assets/calories.png";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  flex: 3;
  padding: 2rem 2rem 2rem 1.5rem;
`;

const Blur = styled.div`
  width: 22rem;
  height: 30rem;
  left: 0;
`;

const Ads = styled.div`
  position: relative;
  width: fit-content;
  margin-top: 4rem;
  border-radius: 4rem;
  padding: 20px 13px;
  background-color: #363d42;
  text-transform: uppercase;
  color: white;
  z-index: 2;
  user-select: none;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: white;
  font-size: 4.5rem;
  font-weight: bold;
  text-transform: uppercase;
  text-overflow: inherit;
  margin-top: 2rem;
  div:nth-of-type(3) {
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
  }
`;

const FiguresContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  div {
    display: flex;
    flex-direction: column;
    span:nth-of-type(1) {
      color: white;
      font-size: 2rem;
    }
    span:nth-of-type(2) {
      color: var(--gray);
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  button {
    color: white;
    width: 8rem;
    :nth-of-type(1) {
      background-color: var(--orange);
    }
    :nth-of-type(2) {
      background-color: transparent;
      border: 2px solid var(--orange);
    }
  }
`;

const RightContainer = styled.div`
  flex: 1;
  position: relative;
  background-color: var(--orange);
`;

const JoinButton = styled.button`
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: black;
  background-color: white;
`;

const HeartContainer = styled(motion("div"))`
  position: absolute;
  top: 7rem;
  right: 4rem;
  width: fit-content;
  background: var(--darkGrey);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  img {
    width: 2rem;
  }
  span:nth-of-type(1) {
    color: var(--gray);
  }
  span:nth-of-type(2) {
    color: white;
    font-size: 1.5rem;
  }
`;

const HeroImage = styled.img`
  position: absolute;
  right: 8rem;
  top: 10rem;
  width: 23rem;
`;

const HeroBackImage = styled(motion("img"))`
  position: absolute;
  right: 20rem;
  top: 4rem;
  width: 15rem;
  z-index: -1;
`;

const Calories = styled(motion("div"))`
  position: absolute;
  right: 28rem;
  top: 31rem;
  padding: 1rem;
  display: flex;
  gap: 2rem;
  border-radius: 5px;
  background-color: var(--caloryCard);
  width: fit-content;
  border-radius: 5px;
  div {
    display: flex;
    flex-direction: column;
    span:nth-of-type(1) {
      color: var(--gray);
    }
    span:nth-of-type(2) {
      color: white;
      font-size: 1.5rem;
    }
  }
`

const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  return (
    <Container>
      <LeftContainer>
        <Blur className="blur" />
        <Header />
        <Ads>the best fitness club in the town
          <motion.div className="ads-box" 
            initial={{left: "236px"}} 
            whileInView={{left: "8px"}}
            transition={{...transition, type: "tween"}}
          />
        </Ads>
        <TitleContainer>
          <div>
            <span className="stroke-text">Shape</span>
            <span> Your</span>
          </div>
          <div>Ideal Body</div>
          <div>In here we will help you to shape and build your ideal body and live up your life to fullest</div>
        </TitleContainer>

        <FiguresContainer>
          <div>
            <span>
              <NumberCount start={100} end={140} delay={3} preFix="+" />
            </span>
            <span>expert coachs</span>
          </div>
          <div>
            <span>
              <NumberCount start={500} end={978} delay={3} preFix="+" />
            </span>
            <span>members joined</span>
          </div>
          <div>
            <span>
              <NumberCount start={20} end={50} delay={3} preFix="+" />
            </span>
            <span>fitness programs</span>
          </div>
        </FiguresContainer>

        <ButtonGroup>
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </ButtonGroup>
      </LeftContainer>

      <RightContainer>
        <JoinButton className="btn">Join Now</JoinButton>

        <HeartContainer 
          initial={{right: "-1rem"}} 
          whileInView={{right: "4rem"}} 
          transition={transition}
        >
          <img src={HeartImg} alt="" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </HeartContainer>
        <HeroImage src={HeroImg} alt="" />
        <HeroBackImage
          initial={{right: "10rem"}} 
          whileInView={{right: "20rem"}} 
          transition={transition}
          src={HeroBackImg} 
          alt="" 
        />

        <Calories
          initial={{right: "36rem"}} 
          whileInView={{right: "28rem"}} 
          transition={transition}
        >
          <img src={CaloriesImg} alt="" />
          <div>
            <span>Calories burned</span>
            <span>220 kcal</span>
          </div>
        </Calories>
      </RightContainer>
    </Container>
  )
}

export default Hero;