import React from "react";
import styled from "styled-components";
import Logo from "../assets/logo.png";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const Image = styled.img`
  width: 10rem;
  height: 3rem;
  cursor: pointer;
`

const Navbar = styled.ul`
  display: flex;
  gap: 2rem;
`;

const NavbarItem = styled.li`
  list-style: none;
  color: white;
  cursor: pointer;
  :hover {
    color: var(--orange);
  }
`

const Header = () => {
  return <Container>
    <Image src={Logo} alt="" />
    <Navbar>
      <NavbarItem>Home</NavbarItem>
      <NavbarItem>Programs</NavbarItem>
      <NavbarItem>Why us</NavbarItem>
      <NavbarItem>Plans</NavbarItem>
      <NavbarItem>Testimonials</NavbarItem>
    </Navbar>
  </Container>
}

export default Header;